import { useEffect, useState, useCallback, lazy, Suspense } from 'react';
import Landing from './components/landing/Landing';
import { Routes, Route, useLocation, useNavigate } from 'react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { loginToken } from './services';

const JoinOrg = lazy(() => import('./components/joinOrg/JoinOrg'));
const VerifyEmail = lazy(() => import('./components/verifyEmail/VerifyEmail'));
const ResetPassword = lazy(() => import('./components/resetPassword/ResetPassword'));
const VerifyNewEmail = lazy(() => import('./components/verifyNewEmail/VerifyNewEmail'));
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));
const Org = lazy(() => import('./components/org/Org'));

const LoadingIndicator = () => (
    <div>Loading...</div>
);

const Redirect = ({ path }) => {
    useEffect(() => window.location.replace(path), []);
    return null;
};

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUserState] = useState({ isLoggedIn: false, });
    const setUser = useCallback(u => {
        if (u.orgs === undefined) { u.orgs = []; }
        //alphabetically sort orgs
        u.orgs = u.orgs.sort((a, b) => a.name.localeCompare(b.name));
        setUserState(u);
        localStorage.setItem('user', JSON.stringify(u));
    }, []);

    useEffect(() => {
        if (user.isLoggedIn !== false) { return; }

        const loginRoutes = ['dashboard', 'j', ''];

        let routeMatch = false;
        const path = location.pathname.split('/')[1];
        for (const r of loginRoutes) {
            if (path === r) {
                routeMatch = r;
                break;
            }
        }

        if (routeMatch === false) { return; }
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser === null) {
            navigate('/', { replace: true, state: location.pathname === '/' ? undefined : { from: location.pathname } });
        } else {
            setUser(storedUser);
            if (routeMatch.length < 1) {
                navigate('/dashboard', { replace: true });
            }
            loginToken(storedUser.token, (err, data) => {
                if (err) { //couldn't log in with token
                    navigate('/', { replace: true, state: location.pathname === '/' ? undefined : { from: location.pathname } });
                } else {
                    setUser(data);
                }
            });
        }
    }, []);

    return (
        <Suspense fallback={<LoadingIndicator />}>
            <GoogleOAuthProvider clientId='978484937841-gg9qpc12jq2ccdom9mqv5mjbibfgu886.apps.googleusercontent.com'>
                <Routes>
                    <Route exact path='/' element={<Landing user={{ id: user.id, email: user.email }} setUser={setUser} />} />
                    <Route path='/dashboard/:orgId' element={user.isLoggedIn === false ? <LoadingIndicator /> : <Org user={user} setUser={setUser} />} />
                    <Route path='/dashboard' element={user.isLoggedIn === false ? <LoadingIndicator /> : <Dashboard user={user} setUser={setUser} />} />
                    <Route path='/j/:invite' element={user.isLoggedIn === false ? <LoadingIndicator /> : <JoinOrg user={user} setUser={setUser} />} />
                    <Route path='/j' element={user.isLoggedIn === false ? <LoadingIndicator /> : <JoinOrg user={user} setUser={setUser} />} />
                    <Route path='/verify-email/:id' element={<VerifyEmail setUser={setUser} />} />
                    <Route path='/verify-email' element={<VerifyEmail setUser={setUser} />} />
                    <Route path='/reset-password/:id' element={<ResetPassword setUser={setUser} />} />
                    <Route path='/reset-password' element={<ResetPassword setUser={setUser} />} />
                    <Route path='/verify-new-email/:id' element={<VerifyNewEmail setUser={setUser} />} />
                    <Route path='/verify-new-email' element={<VerifyNewEmail setUser={setUser} />} />

                    <Route path='/privacy-policy' element={<Redirect path='privacy-policy.html' />} />
                    <Route path='/terms-of-service' element={<Redirect path='privacy-policy.html' />} />
                    <Route path='/tos' element={<Redirect path='privacy-policy.html' />} />
                    <Route path='/markdown' element={<Redirect path='markdown.html' />} />
                    <Route path='/md' element={<Redirect path='markdown.html' />} />
                </Routes>
            </GoogleOAuthProvider>
        </Suspense>
    );
};

export default App;